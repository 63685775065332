//** industry Api

import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
// Axios Imports
import { toast } from 'react-toastify';
import axios from '@src/service/axios';

export const getIndustry = createAsyncThunk('industrySlice/getIndustry', async () => {
    try {
        let response = await axios.get('/industry', {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        return Promise.resolve(response?.data?.data);
    } catch (err) {
        console.log('err', err);
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});

export const getIndustryOption = createAsyncThunk('industrySlice/getIndustryOption', async () => {
    try {
        let response = await axios.get('/industry/options', {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        return Promise.resolve(response?.data?.data);
    } catch (err) {
        console.log('err', err);
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});

export const createIndustry = createAsyncThunk('industrySlice/createIndustry', async (data, { dispatch }) => {
    try {
        let response = await axios.post('/industry', data, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        toast.success(response?.data?.message || 'Industry added successfully.');
        dispatch(getIndustry());
        return Promise.resolve(response);
    } catch (err) {
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        console.log('err', err);
        return Promise.reject(err);
    }
});

export const updateIndustry = createAsyncThunk('industrySlice/updateIndustry', async (data, { dispatch }) => {
    try {
        let response = await axios.put('/industry/' + data?.id, data?.data, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        toast.success(response?.data?.message || 'Industry updated successfully.');
        await dispatch(getIndustry());
        return Promise.resolve(response);
    } catch (err) {
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        console.log('err', err);
        return Promise.reject(err);
    }
});

export const updateIndustryStatus = createAsyncThunk('industrySlice/updateIndustryStatus', async (id, { dispatch }) => {
    try {
        let response = await axios.put('/industry/status/' + id, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        toast.success(response?.data?.message || 'Industry Status updated successfully !');
        dispatch(getIndustry());
        return Promise.resolve(response);
    } catch (err) {
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        console.log('err', err);
        return Promise.reject(err);
    }
});
export const deleteIndustry = createAsyncThunk('industrySlice/deleteIndustry', async (deleteId, { dispatch }) => {
    try {
        let response = await axios.delete('/industry/' + deleteId, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        toast.success(response?.data?.message || 'Industry deleted successfully.');
        await dispatch(getIndustry());
        return Promise.resolve(response);
    } catch (err) {
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        console.log('err', err);
        return Promise.reject(err);
    }
});

export const industrySlice = createSlice({
    name: 'industrySlice',
    initialState: {
        data: [],
        option: []
    },
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(getIndustry.fulfilled, (state, action) => {
            state.data = action.payload;
            return;
        });
        builder.addCase(getIndustryOption.fulfilled, (state, action) => {
            state.option = action.payload;
            return;
        });
    }
});

export default industrySlice.reducer;
