//** industry Api

import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
// Axios Imports
import { toast } from 'react-toastify';
import axios from '@src/service/axios';
import showLoader from '@components/loader';

export const getNotification = createAsyncThunk('notification/getNotification', async () => {
    try {
        let response = await axios.get('/notification-templates', {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        return Promise.resolve(response?.data?.data);
    } catch (err) {
        console.log('err', err);
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});

export const updateNotificationStatus = createAsyncThunk('notification/updateNotificationStatus', async (data, { dispatch }) => {
    try {
        showLoader(true);
        let response = await axios.put(`/notification-templates/status?id=${data?.id}&type=${data?.type}`, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        showLoader(false);
        toast.success(response?.data?.message || 'Status updated successfully.');
        dispatch(getNotification());
        return Promise.resolve(response.data);
    } catch (err) {
        showLoader(false);
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});

export const notification = createSlice({
    name: 'notification',
    initialState: {
        data: []
    },
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(getNotification.fulfilled, (state, action) => {
            state.data = action.payload;
            return;
        });
    }
});

export default notification.reducer;
