//** buying-role Api

import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
// Axios Imports
import { toast } from 'react-toastify';
import axios from '@src/service/axios';

export const getOutcome = createAsyncThunk('outcomeSlice/getOutcome', async () => {
    try {
        let response = await axios.get('/outcome', {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        return Promise.resolve(response?.data?.data);
    } catch (err) {
        console.log('err', err);
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});

export const getOutcomeOption = createAsyncThunk('outcomeSlice/getOutcomeOption', async () => {
    try {
        let response = await axios.get('/outcome/options', {
            headers: {
                'Content-Type': 'application/json'
            }
        });

        return Promise.resolve(response?.data?.data);
    } catch (err) {
        console.log('err', err);
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});

export const createOutcome = createAsyncThunk('outcomeSlice/createOutcome', async (data, { dispatch }) => {
    try {
        let response = await axios.post('/outcome', data, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        toast.success(response?.data?.message || 'Outcome added successfully.');
        dispatch(getOutcome());
        return Promise.resolve(response);
    } catch (err) {
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        console.log('err', err);
        return Promise.reject(err);
    }
});

export const updateOutcome = createAsyncThunk('outcomeSlice/updateOutcome', async (data, { dispatch }) => {
    try {
        let response = await axios.put('/outcome/' + data?.id, data?.data, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        toast.success(response?.data?.message || 'Outcome updated successfully.');
        await dispatch(getOutcome());
        return Promise.resolve(response);
    } catch (err) {
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        console.log('err', err);
        return Promise.reject(err);
    }
});

export const updateOutcomeStatus = createAsyncThunk('outcomeSlice/updateOutcomeStatus', async (id, { dispatch }) => {
    try {
        let response = await axios.put('/outcome/status/' + id, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        toast.success(response?.data?.message || 'Status updated successfully !');
        dispatch(getOutcome());
        return Promise.resolve(response);
    } catch (err) {
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        console.log('err', err);
        return Promise.reject(err);
    }
});
export const deleteOutcome = createAsyncThunk('outcomeSlice/deleteOutcome', async (deleteId, { dispatch }) => {
    try {
        let response = await axios.delete('/outcome/' + deleteId, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        toast.success(response?.data?.message || 'Outcome deleted successfully.');
        await dispatch(getOutcome());
        return Promise.resolve(response);
    } catch (err) {
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        console.log('err', err);
        return Promise.reject(err);
    }
});

export const outcomeSlice = createSlice({
    name: 'outcomeSlice',
    initialState: {
        data: [],
        option: []
    },
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(getOutcome.fulfilled, (state, action) => {
            state.data = action.payload;
            return;
        });
        builder.addCase(getOutcomeOption.fulfilled, (state, action) => {
            state.option = action.payload;
            return;
        });
    }
});

export default outcomeSlice.reducer;
