//** Country Api

import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
// Axios Imports
import { toast } from 'react-toastify';
import axios from '@src/service/axios';

export const getCountry = createAsyncThunk('countrySlice/getCountry', async () => {
    try {
        let response = await axios.get('/country', {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        return Promise.resolve(response?.data?.data);
    } catch (err) {
        console.log('err', err);
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});

export const getCountryOption = createAsyncThunk('countrySlice/getCountryOption', async () => {
    try {
        let response = await axios.get('/country/options', {
            headers: {
                'Content-Type': 'application/json'
            }
        });

        return Promise.resolve(response?.data?.data);
    } catch (err) {
        console.log('err', err);

        toast.error(err?.response?.data?.message || 'Something went wrong!');

        return Promise.reject(err);
    }
});

export const deleteCountry = createAsyncThunk('countrySlice/deleteCountry', async (deleteId, { dispatch }) => {
    try {
        let response = await axios.delete('/country/' + deleteId, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        toast.success(response?.data?.message || 'Country deleted successfully.');
        await dispatch(getCountry());
        return Promise.resolve(response);
    } catch (err) {
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        console.log('err', err);
        return Promise.reject(err);
    }
});

export const createCountry = createAsyncThunk('countrySlice/createCountry', async (data, { dispatch }) => {
    try {
        let response = await axios.post('/country', data, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        toast.success(response?.data?.message || 'Country added successfully.');
        await dispatch(getCountry());
        return Promise.resolve(response);
    } catch (err) {
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        console.log('err', err);
        return Promise.reject(err);
    }
});

export const updateCountry = createAsyncThunk('countrySlice/updateCountry', async (data, { dispatch }) => {
    try {
        let response = await axios.put('/country/' + data?.id, data?.data, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        toast.success(response?.data?.message || 'Country updated successfully.');
        await dispatch(getCountry());
        return Promise.resolve(response);
    } catch (err) {
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        console.log('err', err);
        return Promise.reject(err);
    }
});

export const updateCountryStatus = createAsyncThunk('countrySlice/updateCountryStatus', async (id, { dispatch }) => {
    try {
        let response = await axios.put('/country/status/' + id, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        toast.success(response?.data?.message || ' Status updated successfully !');
        dispatch(getCountry());
        return Promise.resolve(response);
    } catch (err) {
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        console.log('err', err);
        return Promise.reject(err);
    }
});

export const countrySlice = createSlice({
    name: 'countrySlice',
    initialState: {
        data: [],
        option: []
    },
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(getCountry.fulfilled, (state, action) => {
            state.data = action.payload;

            return;
        });
        builder.addCase(getCountryOption.fulfilled, (state, action) => {
            state.option = action.payload;

            return;
        });
    }
});

export default countrySlice.reducer;
