//** Lead Api

import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
// Axios Imports
import { toast } from 'react-toastify';
import axios from '@src/service/axios';
// import themeConfig from '../../../configs/themeConfig';
const metaAccessToken = localStorage.getItem('meta-act');

export const addAccount = createAsyncThunk('meta/addAccount', async (data) => {
    try {
        let response = await axios.get(`meta/ad-accounts?access_token=${data?.data}`, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        return response?.data?.data;
    } catch (err) {
        console.log('err', err);
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        throw err;
    }
});

export const addPages = createAsyncThunk('meta/addPages', async (data) => {
    try {
        let response = await axios.get(`meta/pages?access_token=${data?.data}`, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        return response?.data?.data;
    } catch (err) {
        console.log('err', err);
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        throw err;
    }
});

export const addForm = createAsyncThunk('meta/addForm', async (data) => {
    try {
        let response = await axios.get(`/meta/forms?pageId=${data.pageId}&page_token=${data.pageToken}`, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        return response?.data?.data;
    } catch (err) {
        console.log('err', err);
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        throw err;
    }
});

export const metaSlice = createSlice({
    name: 'metaSlice',
    initialState: {
        accounts: [],
        pages: [],
        form: []
    },
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(addAccount.fulfilled, (state, action) => {
            state.accounts = action.payload;
        });
        builder.addCase(addPages.fulfilled, (state, action) => {
            state.pages = action.payload;
        });
        builder.addCase(addForm.fulfilled, (state, action) => {
            state.form = action.payload;
        });
    }
});

export default metaSlice.reducer;
