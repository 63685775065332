//** deals Api

import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
// Axios Imports
import { toast } from 'react-toastify';
import axios from '@src/service/axios';
import showLoader from '@components/loader';

export const getDeals = createAsyncThunk('dealsSlice/getDeals', async () => {
    try {
        let response = await axios.get('/deals', {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        return Promise.resolve(response?.data?.data);
    } catch (err) {
        console.log('err', err);
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});

export const getDealById = createAsyncThunk('dealsSlice/getDealById', async (id) => {
    try {
        let response = await axios.get('/deals/' + id, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        return Promise.resolve(response?.data?.data);
    } catch (err) {
        console.log('err', err);
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});

export const getDealsOption = createAsyncThunk('dealsSlice/getDealsOption', async () => {
    try {
        let response = await axios.get('/deals/options', {
            headers: {
                'Content-Type': 'application/json'
            }
        });

        return Promise.resolve(response?.data?.data);
    } catch (err) {
        console.log('err', err);
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});

export const createDeal = createAsyncThunk('dealsSlice/createDeal', async (data, { dispatch }) => {
    try {
        showLoader(true);
        let response = await axios.post('/deals', data, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        showLoader(false);
        toast.success(response?.data?.message || 'Deal created successfully.');
        dispatch(getDealByFilter());
        return Promise.resolve(response);
    } catch (err) {
        showLoader(false);
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        console.log('err', err);
        return Promise.reject(err);
    }
});

export const updateDeal = createAsyncThunk('dealsSlice/updateDeal', async (data, { dispatch }) => {
    try {
        showLoader(true);
        let response = await axios.put('/deals/' + data?.id, data?.data, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        showLoader(false);
        toast.success(response?.data?.message || 'Deal updated successfully.');
        await dispatch(getDealByFilter());
        return Promise.resolve(response);
    } catch (err) {
        showLoader(false);
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        console.log('err', err);
        return Promise.reject(err);
    }
});

export const updateDealStatus = createAsyncThunk('dealsSlice/updateDealStatus', async (id, { dispatch }) => {
    try {
        showLoader(true);
        let response = await axios.put('/deals/status/' + id, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        showLoader(false);
        toast.success(response?.data?.message || 'Status updated successfully !');
        dispatch(getDealByFilter());
        return Promise.resolve(response);
    } catch (err) {
        showLoader(false);
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        console.log('err', err);
        return Promise.reject(err);
    }
});
export const deleteDeal = createAsyncThunk('dealsSlice/deleteDeal', async (deleteId, { dispatch }) => {
    try {
        let response = await axios.delete('/deals/' + deleteId, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        toast.success(response?.data?.message || 'Deal deleted successfully.');
        await dispatch(getDealByFilter());
        return Promise.resolve(response);
    } catch (err) {
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        console.log('err', err);
        return Promise.reject(err);
    }
});

//deal field-filter options
export const getDealFilterOption = createAsyncThunk('leadSourceSlice/getDealFilterOption', async () => {
    try {
        let response = await axios.get('/deal-filter/options', {
            headers: {
                'Content-Type': 'application/json'
            }
        });

        return Promise.resolve(response?.data?.data);
    } catch (err) {
        console.log('err', err);
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});

export const getDealByFilter = createAsyncThunk('leadSlice/getDealByFilter', async (data, { dispatch }) => {
    try {
        let response = await axios.post('/deal-filter', data, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        // toast.success(response?.data?.message || 'Lead added successfully.');
        return Promise.resolve(response?.data?.data);
    } catch (err) {
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        console.log('err', err);
        return Promise.reject(err);
    }
});

export const getDealNoteById = createAsyncThunk('dealsSlice/getDealNoteById', async (data) => {
    try {
        let response = await axios.get('/deal-note/' + data, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        return Promise.resolve(response?.data?.data);
    } catch (err) {
        console.log('err', err);
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});

export const getDealAttachmentById = createAsyncThunk('dealsSlice/getDealAttachmentById', async (data) => {
    try {
        let response = await axios.get('/deal-attachment/' + data, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        return Promise.resolve(response?.data?.data);
    } catch (err) {
        console.log('err', err);
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});

export const getDealTodoById = createAsyncThunk('dealsSlice/getDealTodoById', async (data) => {
    try {
        let response = await axios.get('/deal/todo/' + data?.id + `?status=${data?.status}`, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        return Promise.resolve(response?.data?.data);
    } catch (err) {
        console.log('err', err);
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});

export const getDealCallById = createAsyncThunk('dealsSlice/getDealCallById', async (data) => {
    try {
        let response = await axios.get('/deal/schedule-calls/' + data?.id + `?status=${data?.status}`, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        return Promise.resolve(response?.data?.data);
    } catch (err) {
        console.log('err', err);
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});

export const getDealMeetingById = createAsyncThunk('dealsSlice/getDealMeetingById', async (data) => {
    try {
        let response = await axios.get('/deal/meetings/' + data?.id + `?status=${data?.status}`, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        return Promise.resolve(response?.data?.data);
    } catch (err) {
        console.log('err', err);
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});

export const createNote = createAsyncThunk('dealsSlice/createNote', async (data) => {
    try {
        let response = await axios.post('/note', data, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        });
        return Promise.resolve(response?.data?.data);
    } catch (err) {
        console.log('err', err);
        // toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});

export const createAttachment = createAsyncThunk('dealsSlice/createAttachment', async (data) => {
    try {
        let response = await axios.post('/attachment', data, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        });
        return Promise.resolve(response?.data?.data);
    } catch (err) {
        console.log('err', err);
        // toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});

export const createDealTodo = createAsyncThunk('dealsSlice/createDealTodo', async (data) => {
    try {
        let response = await axios.post('/todo', data, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        return Promise.resolve(response?.data?.data);
    } catch (err) {
        console.log('err', err);
        // toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});

export const updateNote = createAsyncThunk('dealsSlice/updateNote', async (data, { dispatch }) => {
    try {
        let response = await axios.put('/note/' + data?.id, data?.data, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        });
        return Promise.resolve(response?.data?.data);
    } catch (err) {
        console.log('err', err);
        // toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});

export const updateDealTodo = createAsyncThunk('dealsSlice/updateDealTodo', async (data, { dispatch }) => {
    try {
        let response = await axios.put('/todo/' + data?.id, data?.data, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        return Promise.resolve(response?.data?.data);
    } catch (err) {
        console.log('err', err);
        // toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});

export const updateAttachment = createAsyncThunk('dealsSlice/updateAttachment', async (data, { dispatch }) => {
    try {
        let response = await axios.put('/attachment/' + data?.id, data?.data, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        });
        return Promise.resolve(response?.data?.data);
    } catch (err) {
        console.log('err', err);
        // toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});

export const deleteContactNote = createAsyncThunk('dealsSlice/deleteContactNote', async (deleteId, { dispatch }) => {
    try {
        let response = await axios.delete('/note/' + deleteId, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        return Promise.resolve(response);
    } catch (err) {
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        console.log('err', err);
        return Promise.reject(err);
    }
});

export const deleteContactAttachment = createAsyncThunk('dealsSlice/deleteContactAttachment', async (deleteId, { dispatch }) => {
    try {
        let response = await axios.delete('/attachment/' + deleteId, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        return Promise.resolve(response);
    } catch (err) {
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        console.log('err', err);
        return Promise.reject(err);
    }
});

export const deleteDealTodo = createAsyncThunk('dealsSlice/deleteDealTodo', async (deleteId, { dispatch }) => {
    try {
        let response = await axios.delete('/todo/' + deleteId, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        return Promise.resolve(response);
    } catch (err) {
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        console.log('err', err);
        return Promise.reject(err);
    }
});
// export const deleteContactCall = createAsyncThunk('dealsSlice/deleteContactCall', async (deleteId, { dispatch }) => {
//     try {
//         let response = await axios.delete('/contact/schedule-calls/' + deleteId, {
//             headers: {
//                 'Content-Type': 'application/json'
//             }
//         });
//         return Promise.resolve(response);
//     } catch (err) {
//         toast.error(err?.response?.data?.message || 'Something went wrong!');
//         console.log('err', err);
//         return Promise.reject(err);
//     }
// });
export const getDealTimeLine = createAsyncThunk('dealsSlice/getDealTimeLine', async (id, { dispatch }) => {
    try {
        let response = await axios.get('/deal-stage/history/' + id, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        // toast.success(response?.data?.message);
        return Promise.resolve(response?.data?.data);
    } catch (err) {
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        console.log('err', err);
        return Promise.reject(err);
    }
});

//account field-filter options
export const getContactFilterOption = createAsyncThunk('leadSourceSlice/getContactFilterOption', async () => {
    try {
        let response = await axios.get('/deal-filter/options', {
            headers: {
                'Content-Type': 'application/json'
            }
        });

        return Promise.resolve(response?.data?.data);
    } catch (err) {
        console.log('err', err);
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});

export const getContactByFilter = createAsyncThunk('leadSlice/getContactByFilter', async (data, { dispatch }) => {
    try {
        let response = await axios.post('/deal-filteration/filter-by-queries', data, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        return Promise.resolve(response?.data?.data);
    } catch (err) {
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        console.log('err', err);
        return Promise.reject(err);
    }
});

export const createCampaignContact = createAsyncThunk('dealsSlice/createCampaignContact', async (data) => {
    try {
        let response = await axios.post('/deal/campaign', data, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        return Promise.resolve(response?.data?.data);
    } catch (err) {
        console.log('err', err);
        // toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});

// export const getCampaignById = createAsyncThunk('dealsSlice/getCampaignById', async (id) => {
//     try {
//         let response = await axios.get('/contact/deal/' + id, {
//             headers: {
//                 'Content-Type': 'application/json'
//             }
//         });
//         return Promise.resolve(response?.data?.data);
//     } catch (err) {
//         console.log('err', err);
//         // toast.error(err?.response?.data?.message || 'Something went wrong!');
//         return Promise.reject(err);
//     }
// });
export const deleteCampaignContact = createAsyncThunk('leadProfileSlice/deleteCampaignContact', async (deleteId, { dispatch }) => {
    try {
        let response = await axios.delete('/contact/campaign/' + deleteId, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        });
        return Promise.resolve(response?.data?.data);
    } catch (err) {
        console.log('err', err);
        // toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});

export const deleteBulkDeals = createAsyncThunk('accountSlice/deleteBulkDeals', async (deleteIds, { dispatch }) => {
    try {
        let response = await axios.put('/deals-bulk-delete', deleteIds, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        toast.success(response?.data?.message || 'Selected Deals deleted successfully.');
        dispatch(getDealByFilter());
        return Promise.resolve(response);
    } catch (err) {
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        console.log('err', err);
        return Promise.reject(err);
    }
});

export const DealsBulkUpdate = createAsyncThunk('accountSlice/DealsBulkUpdate', async (data, { dispatch }) => {
    try {
        let response = await axios.put('/deals-bulk-update', data, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        toast.success(response?.data?.message || 'Bulk updated successfully !');
        dispatch(getDealByFilter());
        return Promise.resolve(response);
    } catch (err) {
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        console.log('err', err);
        return Promise.reject(err);
    }
});

export const getDealFieldOption = createAsyncThunk('contactSlice/getDealFieldOption', async () => {
    try {
        let response = await axios.get('/deal-field/options', {
            headers: {
                'Content-Type': 'application/json'
            }
        });

        return Promise.resolve(response?.data?.data);
    } catch (err) {
        console.log('err', err);
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});

export const getDealByPipeline = createAsyncThunk('contactSlice/getDealByPipeline', async (data, { dispatch }) => {
    try {
        let response = await axios.post('/deals-pipeline-filter', data, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        // toast.success(response?.data?.message || 'Lead added successfully.');
        return Promise.resolve(response?.data?.data);
    } catch (err) {
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        console.log('err', err);
        return Promise.reject(err);
    }
});

export const updateDealPipelineTasks = createAsyncThunk('accountSlice/updateDealPipelineTasks', async (data, { dispatch }) => {
    try {
        let response = await axios.put('/change/deal-stage', data, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        // await dispatch(getAccountPipeline());
        return Promise.resolve(response);
    } catch (err) {
        // toast.error(err?.response?.data?.message || 'Something went wrong!');
        console.log('err', err);
        return Promise.reject(err);
    }
});

export const getDealCustomField = createAsyncThunk('accountSlice/getDealCustomField', async () => {
    try {
        let response = await axios.get('/deals-custom-field', {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        return Promise.resolve(response?.data?.data);
    } catch (err) {
        console.log('err', err);
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});

export const dealsSlice = createSlice({
    name: 'dealsSlice',
    initialState: {
        data: [],
        option: [],
        selectedDeal: {},
        dealFilterOptions: [],
        dealFilter: [],
        note: [],
        attachment: [],
        todo: [],
        calls: [],
        contactFilterOptions: [],
        contactFilter: [],
        meeting: [],
        campaign: [],
        dealFieldOptions: [],
        dealPipelineFilter: [],
        dealCustomField: [],
        timeline: []
    },
    reducers: {
        removeSelectedDeal: (state, action) => {
            state.selectedDeal = [];
        }
    },
    extraReducers: (builder) => {
        builder.addCase(getDeals.fulfilled, (state, action) => {
            state.data = action.payload;
            return;
        });
        builder.addCase(getDealsOption.fulfilled, (state, action) => {
            state.option = action.payload;
            return;
        });
        builder.addCase(getDealById.fulfilled, (state, action) => {
            state.selectedDeal = action.payload;
            return;
        });
        builder.addCase(getDealFilterOption.fulfilled, (state, action) => {
            state.dealFilterOptions = action.payload;
            return;
        });
        builder.addCase(getDealByFilter.fulfilled, (state, action) => {
            state.dealFilter = action.payload;
            return;
        });
        builder.addCase(getDealNoteById.fulfilled, (state, action) => {
            state.note = action.payload;
            return;
        });
        builder.addCase(getDealAttachmentById.fulfilled, (state, action) => {
            state.attachment = action.payload;
            return;
        });
        builder.addCase(getDealTodoById.fulfilled, (state, action) => {
            state.todo = action.payload;
            return;
        });
        builder.addCase(getDealCallById.fulfilled, (state, action) => {
            state.calls = action.payload;
            return;
        });
        builder.addCase(getContactFilterOption.fulfilled, (state, action) => {
            state.contactFilterOptions = action.payload;
            return;
        });
        builder.addCase(getContactByFilter.fulfilled, (state, action) => {
            state.contactFilter = action.payload;
            return;
        });
        builder.addCase(getDealMeetingById.fulfilled, (state, action) => {
            state.meeting = action.payload;
            return;
        });
        builder.addCase(getDealFieldOption.fulfilled, (state, action) => {
            state.dealFieldOptions = action.payload;
            return;
        });
        builder.addCase(getDealByPipeline.fulfilled, (state, action) => {
            state.dealPipelineFilter = action.payload;
            return;
        });
        builder.addCase(getDealCustomField.fulfilled, (state, action) => {
            state.dealCustomField = action.payload;
            return;
        });
        builder.addCase(getDealTimeLine.fulfilled, (state, action) => {
            state.timeline = action.payload;
            return;
        });
    }
});

export const { removeSelectedDeal } = dealsSlice.actions;

export default dealsSlice.reducer;
