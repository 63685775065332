//** industry Api

import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
// Axios Imports
import { toast } from 'react-toastify';
import axios from '@src/service/axios';

export const getCustomActivity = createAsyncThunk('customActivity/getCustomActivity', async () => {
    try {
        let response = await axios.get('/custom-activity', {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        return Promise.resolve(response?.data?.data);
    } catch (err) {
        console.log('err', err);
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});

export const getCustomActivityById = createAsyncThunk('customActivity/getCustomActivityById', async (id) => {
    try {
        let response = await axios.get('/custom-activity/' + id, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        return Promise.resolve(response?.data?.data);
    } catch (err) {
        console.log('err', err);
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});

export const getCustomActivityOption = createAsyncThunk('customActivity/getCustomActivityOption', async () => {
    try {
        let response = await axios.get('/custom-activity/options', {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        return Promise.resolve(response?.data?.data);
    } catch (err) {
        console.log('err', err);
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});

export const createCustomActivity = createAsyncThunk('customActivity/createCustomActivity', async (data, { dispatch }) => {
    try {
        let response = await axios.post('/custom-activity', data, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        toast.success(response?.data?.message || 'Custom activity added successfully.');
        dispatch(getCustomActivity());
        return Promise.resolve(response);
    } catch (err) {
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        console.log('err', err);
        return Promise.reject(err);
    }
});

//custom activity form history
export const getCustomActivityHistoryById = createAsyncThunk('leadProfileSlice/getCustomActivityHistoryById', async (id) => {
    try {
        let response = await axios.get('/custom-activity-form/history/' + id, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        return Promise.resolve(response?.data?.data);
    } catch (err) {
        console.log('err', err);
        // toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});

export const createCustomActivityForm = createAsyncThunk('customActivity/createCustomActivityForm', async (data, { dispatch }) => {
    try {
        let response = await axios.post('/custom-activity-form', data, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        toast.success(response?.data?.message || 'Custom activity added successfully.');
        dispatch(getCustomActivityHistoryById(data?.leadId));
        return Promise.resolve(response);
    } catch (err) {
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        console.log('err', err);
        return Promise.reject(err);
    }
});
export const updateCustomActivity = createAsyncThunk('customActivity/updateCustomActivity', async (data, { dispatch }) => {
    try {
        let response = await axios.put('/custom-activity/' + data?.id, data?.data, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        toast.success(response?.data?.message || 'Custom Field updated successfully.');
        await dispatch(getCustomActivity());
        return Promise.resolve(response);
    } catch (err) {
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        console.log('err', err);
        return Promise.reject(err);
    }
});

export const updateCustomActivityStatus = createAsyncThunk('customActivity/updateCustomActivityStatus', async (id, { dispatch }) => {
    try {
        let response = await axios.put('/custom-activity/status/' + id, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        toast.success(response?.data?.message || 'Custom Activity Status updated successfully !');
        dispatch(getCustomActivity());
        return Promise.resolve(response);
    } catch (err) {
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        console.log('err', err);
        return Promise.reject(err);
    }
});
//update custom activity History Form
export const updateCustomActivityHistory = createAsyncThunk('customActivity/updateCustomActivityHistory', async (data, { dispatch }) => {
    try {
        let response = await axios.put('/custom-activity-form', data, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        toast.success(response?.data?.message || 'Custom Activity Form  updated successfully.');
        // await dispatch(getCustomActivity());
        await dispatch(getCustomActivityHistoryById(data?.leadId));
        return Promise.resolve(response);
    } catch (err) {
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        console.log('err', err);
        return Promise.reject(err);
    }
});

export const deleteCustomActivity = createAsyncThunk('customActivity/deleteCustomActivity', async (deleteId, { dispatch }) => {
    try {
        let response = await axios.delete('/custom-activity/' + deleteId, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        toast.success(response?.data?.message || 'Custom Activity deleted successfully.');
        await dispatch(getCustomActivity());
        return Promise.resolve(response);
    } catch (err) {
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        console.log('err', err);
        return Promise.reject(err);
    }
});

//delete custom activity history form
export const deleteCustomActivityHistory = createAsyncThunk('customActivity/deleteCustomActivityHistory', async (id, { dispatch }) => {
    try {
        let response = await axios.delete('/custom-activity-form/' + id, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        toast.success(response?.data?.message || 'Custom Activity form deleted successfully.');
        // await dispatch(getCustomActivityHistoryById(data?.leadId));
        return Promise.resolve(response);
    } catch (err) {
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        console.log('err', err);
        return Promise.reject(err);
    }
});

export const customActivity = createSlice({
    name: 'customActivity',
    initialState: {
        data: [],
        selectedActivity: {},
        option: [],
        activityHistory: []
    },
    reducers: {
        removeSelectedCustomActivity: (state, action) => {
            state.selectedActivity = {};
        }
    },
    extraReducers: (builder) => {
        builder.addCase(getCustomActivity.fulfilled, (state, action) => {
            state.data = action.payload;
            return;
        });
        builder.addCase(getCustomActivityById.fulfilled, (state, action) => {
            state.selectedActivity = action.payload;
            return;
        });
        builder.addCase(getCustomActivityOption.fulfilled, (state, action) => {
            state.option = action.payload;
            return;
        });
        builder.addCase(getCustomActivityHistoryById.fulfilled, (state, action) => {
            state.activityHistory = action.payload;
            return;
        });
    }
});
export const { removeSelectedCustomActivity } = customActivity.actions;

export default customActivity.reducer;
