//** List Api

import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
// Axios Imports
import { toast } from 'react-toastify';
import axios from '@src/service/axios';

export const getCampaign = createAsyncThunk('campaignSlice/getCampaign', async () => {
    try {
        let response = await axios.get('/campaigns', {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        return Promise.resolve(response?.data?.data);
    } catch (err) {
        console.log('err', err);
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});

export const getCampaignOption = createAsyncThunk('campaignSlice/getCampaignOption', async () => {
    try {
        let response = await axios.get('/campaigns/options', {
            headers: {
                'Content-Type': 'application/json'
            }
        });

        return Promise.resolve(response?.data?.data);
    } catch (err) {
        console.log('err', err);
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});

export const createCampaign = createAsyncThunk('campaignSlice/createCampaign', async (data, { dispatch }) => {
    try {
        let response = await axios.post('/campaigns', data, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        toast.success(response?.data?.message || 'Campaign  added successfully.');
        dispatch(getCampaign());
        return Promise.resolve(response);
    } catch (err) {
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        console.log('err', err);
        return Promise.reject(err);
    }
});

export const updateCampaign = createAsyncThunk('campaignSlice/updateCampaign', async (data, { dispatch }) => {
    try {
        let response = await axios.put('/campaigns/' + data?.id, data?.data, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        toast.success(response?.data?.message || 'Campaign  updated successfully.');
        await dispatch(getCampaign());
        return Promise.resolve(response);
    } catch (err) {
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        console.log('err', err);
        return Promise.reject(err);
    }
});

export const updateCampaignStatus = createAsyncThunk('campaignSlice/updateCampaignStatus', async (id, { dispatch }) => {
    try {
        let response = await axios.put('/campaigns/status/' + id, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        toast.success(response?.data?.message || 'Campaign  Status updated successfully !');
        dispatch(getCampaign());
        return Promise.resolve(response);
    } catch (err) {
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        console.log('err', err);
        return Promise.reject(err);
    }
});

export const deleteCampaign = createAsyncThunk('campaignSlice/deleteCampaign', async (deleteId, { dispatch }) => {
    try {
        let response = await axios.delete('/campaigns/' + deleteId, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        toast.success(response?.data?.message || 'Campaign  deleted successfully.');
        await dispatch(getCampaign());
        return Promise.resolve(response);
    } catch (err) {
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        console.log('err', err);
        return Promise.reject(err);
    }
});

export const campaignSlice = createSlice({
    name: 'campaignSlice',
    initialState: {
        data: [],
        option: []
    },
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(getCampaign.fulfilled, (state, action) => {
            state.data = action.payload;
            return;
        });
        builder.addCase(getCampaignOption.fulfilled, (state, action) => {
            state.option = action.payload;
            return;
        });
    }
});

export default campaignSlice.reducer;
