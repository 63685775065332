//** industry Api

import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
// Axios Imports
import { toast } from 'react-toastify';
import axios from '@src/service/axios';

export const getManufacturer = createAsyncThunk('manufacturerSlice/getManufacturer', async () => {
    try {
        let response = await axios.get('/manufacturer', {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        return Promise.resolve(response?.data?.data);
    } catch (err) {
        console.log('err', err);
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});

export const getManufacturerOption = createAsyncThunk('manufacturerSlice/getManufacturerOption', async () => {
    try {
        let response = await axios.get('/manufacturer/options', {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        return Promise.resolve(response?.data?.data);
    } catch (err) {
        console.log('err', err);
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});

export const createManufacturer = createAsyncThunk('manufacturerSlice/createManufacturer', async (data, { dispatch }) => {
    try {
        let response = await axios.post('/manufacturer', data, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        toast.success(response?.data?.message || 'manufacturer added successfully.');
        dispatch(getManufacturer());
        return Promise.resolve(response);
    } catch (err) {
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        console.log('err', err);
        return Promise.reject(err);
    }
});

export const updateManufacturer = createAsyncThunk('manufacturerSlice/updateManufacturer', async (data, { dispatch }) => {
    try {
        let response = await axios.put('/manufacturer/' + data?.id, data?.data, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        toast.success(response?.data?.message || 'Industry updated successfully.');
        await dispatch(getManufacturer());
        return Promise.resolve(response);
    } catch (err) {
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        console.log('err', err);
        return Promise.reject(err);
    }
});

export const updateManufacturerStatus = createAsyncThunk('manufacturerSlice/updateManufacturerStatus', async (id, { dispatch }) => {
    try {
        let response = await axios.put('/manufacturer/status/' + id, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        toast.success(response?.data?.message || 'Manufacturer Status updated successfully !');
        dispatch(getManufacturer());
        return Promise.resolve(response);
    } catch (err) {
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        console.log('err', err);
        return Promise.reject(err);
    }
});
export const deleteManufacturer = createAsyncThunk('manufacturerSlice/deleteManufacturer', async (deleteId, { dispatch }) => {
    try {
        let response = await axios.delete('/manufacturer/' + deleteId, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        toast.success(response?.data?.message || 'Manufacturer deleted successfully.');
        await dispatch(getManufacturer());
        return Promise.resolve(response);
    } catch (err) {
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        console.log('err', err);
        return Promise.reject(err);
    }
});

export const manufacturerSlice = createSlice({
    name: 'manufacturerSlice',
    initialState: {
        data: [],
        option: []
    },
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(getManufacturer.fulfilled, (state, action) => {
            state.data = action.payload;
            return;
        });
        builder.addCase(getManufacturerOption.fulfilled, (state, action) => {
            state.option = action.payload;
            return;
        });
    }
});

export default manufacturerSlice.reducer;
