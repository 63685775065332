//** Lead Api

import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
// Axios Imports
import { toast } from 'react-toastify';
import axios from '@src/service/axios';

export const getToDos = createAsyncThunk('toDosSlice/getToDos', async (data) => {
    try {
        let response = await axios.get('/todo?status=' + data, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        return Promise.resolve(response?.data?.data);
    } catch (err) {
        console.log('err', err);
        // toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});

export const getToDoById = createAsyncThunk('toDosSlice/getToDoById', async (data) => {
    try {
        let response = await axios.get('/todo/' + data, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        return Promise.resolve(response?.data?.data);
    } catch (err) {
        console.log('err', err);
        // toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});

export const createToDo = createAsyncThunk('toDosSlice/createToDo', async (data, { dispatch }) => {
    try {
        let response = await axios.post('/todo', data, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        dispatch(getToDoByFilter({ statusData: '0' }));
        return Promise.resolve(response?.data);
    } catch (err) {
        console.log('err', err);
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});

export const updateTodo = createAsyncThunk('toDosSlice/updateTodo', async (data, { dispatch }) => {
    try {
        let response = await axios.put('/todo/' + data.id, data.data, {
            headers: {
                'Content-Type': 'application/json'
            }
        });

        return Promise.resolve(response?.data?.data);
    } catch (err) {
        console.log('err', err);
        // toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});

export const updateTodoStatus = createAsyncThunk('toDosSlice/updateTodoStatus', async (data, { dispatch }) => {
    try {
        let response = await axios.put('/todo-status/' + data.id, data.data, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        return Promise.resolve(response?.data?.data);
    } catch (err) {
        console.log('err', err);
        // toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});

export const deleteTodo = createAsyncThunk('toDosSlice/deleteTodo', async (deleteId, { dispatch }) => {
    try {
        let response = await axios.delete('/todo/' + deleteId, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        return Promise.resolve(response);
    } catch (err) {
        console.log('err', err);
        return Promise.reject(err);
    }
});

//to-do field-filter options
export const getToDoFilterOption = createAsyncThunk('leadSourceSlice/getToDoFilterOption', async () => {
    try {
        let response = await axios.get('/todo-filter/options', {
            headers: {
                'Content-Type': 'application/json'
            }
        });

        return Promise.resolve(response?.data?.data);
    } catch (err) {
        console.log('err', err);
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});

export const getToDoByFilter = createAsyncThunk('leadSlice/getToDoByFilter', async (data, { dispatch }) => {
    try {
        let response = await axios.post('/todo-filter', data, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        // toast.success(response?.data?.message || 'Lead added successfully.');
        return Promise.resolve(response?.data?.data);
    } catch (err) {
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        console.log('err', err);
        return Promise.reject(err);
    }
});

export const getToDosNoteById = createAsyncThunk('accountSlice/getToDosNoteById', async (data) => {
    try {
        let response = await axios.get('/todo-note/' + data, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        });
        return Promise.resolve(response?.data?.data);
    } catch (err) {
        console.log('err', err);
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});
export const createToDoNote = createAsyncThunk('toDosSlice/createToDoNote', async (data, { dispatch }) => {
    try {
        let response = await axios.post('/note', data, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        });
        dispatch(getToDos(0));
        return Promise.resolve(response?.data?.data);
    } catch (err) {
        console.log('err', err);
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});

export const updateTodoNote = createAsyncThunk('toDosSlice/updateTodoNote', async (data, { dispatch }) => {
    try {
        let response = await axios.put('/note/' + data.id, data.data, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        });
        return Promise.resolve(response?.data?.data);
    } catch (err) {
        console.log('err', err);
        // toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});

export const deleteTodoNote = createAsyncThunk('toDosSlice/deleteTodoNote', async (deleteId, { dispatch }) => {
    try {
        let response = await axios.delete('/note/' + deleteId, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        });
        return Promise.resolve(response);
    } catch (err) {
        console.log('err', err);
        return Promise.reject(err);
    }
});

export const getTodoAttachmentById = createAsyncThunk('toDosSlice/getTodoAttachmentById', async (data) => {
    try {
        let response = await axios.get('/todo-attachment/' + data, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        });
        return Promise.resolve(response?.data?.data);
    } catch (err) {
        console.log('err', err);
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});

export const deleteTodoAttachment = createAsyncThunk('toDosSlice/deleteTodoAttachment', async (deleteId, { dispatch }) => {
    try {
        let response = await axios.delete('/attachment/' + deleteId, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        });
        return Promise.resolve(response);
    } catch (err) {
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        console.log('err', err);
        return Promise.reject(err);
    }
});

export const createAttachment = createAsyncThunk('toDosSlice/createAttachment', async (data) => {
    try {
        let response = await axios.post('/attachment', data, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        });
        return Promise.resolve(response?.data?.data);
    } catch (err) {
        console.log('err', err);
        // toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});

export const getDealOption = createAsyncThunk('toDosSlice/getDealOption', async () => {
    try {
        let response = await axios.get('/deals/options', {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        return Promise.resolve(response?.data?.data);
    } catch (err) {
        console.log('err', err);
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});

//for bulk update field
export const getTodoFieldOption = createAsyncThunk('toDosSlice/getTodoFieldOption', async () => {
    try {
        let response = await axios.get('/todo-field/options', {
            headers: {
                'Content-Type': 'application/json'
            }
        });

        return Promise.resolve(response?.data?.data);
    } catch (err) {
        console.log('err', err);
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});

//lead bulk update
export const todoBulkUpdate = createAsyncThunk('toDosSlice/todoBulkUpdate', async (data, { dispatch }) => {
    try {
        let response = await axios.put('/todo-bulk-update', data, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        toast.success(response?.data?.message || 'Bulk updated successfully !');
        await dispatch(getToDos());
        return Promise.resolve(response);
    } catch (err) {
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        console.log('err', err);
        return Promise.reject(err);
    }
});

// bulk delete
export const deleteBulkTodo = createAsyncThunk('toDosSlice/deleteBulkTodo', async (deleteIds, { dispatch }) => {
    try {
        let response = await axios.put('/todo-bulk-delete', deleteIds, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        toast.success(response?.data?.message || 'ToDo deleted successfully.');
        dispatch(getToDoByFilter({ statusData: '0' }));
        return Promise.resolve(response);
    } catch (err) {
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        console.log('err', err);
        return Promise.reject(err);
    }
});

export const getToDosCustomField = createAsyncThunk('toDosSlice/getToDosCustomField', async () => {
    try {
        let response = await axios.get('/todo-custom-field', {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        return Promise.resolve(response?.data?.data);
    } catch (err) {
        console.log('err', err);
        // toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});

export const toDosSlice = createSlice({
    name: 'toDosSlice',
    initialState: {
        data: [],
        option: [],
        selectedTodo: [],
        toDoFilterOptions: [],
        toDoFilter: [],
        note: [],
        attachment: [],
        todoFieldOptions: [],
        toDosCustomField: [],
        dealOptions: []
    },
    reducers: {
        removeSelectedTodo: (state, action) => {
            state.selectedTodo = [];
        }
    },
    extraReducers: (builder) => {
        builder.addCase(getToDos.fulfilled, (state, action) => {
            state.data = action.payload;
            return;
        });
        builder.addCase(getToDoById.fulfilled, (state, action) => {
            state.selectedTodo = action.payload;
            return;
        });
        builder.addCase(getToDoFilterOption.fulfilled, (state, action) => {
            state.toDoFilterOptions = action.payload;
            return;
        });
        builder.addCase(getToDoByFilter.fulfilled, (state, action) => {
            state.toDoFilter = action.payload;
            return;
        });
        builder.addCase(getToDosNoteById.fulfilled, (state, action) => {
            state.note = action.payload;
            return;
        });
        builder.addCase(getTodoAttachmentById.fulfilled, (state, action) => {
            state.attachment = action.payload;
            return;
        });
        builder.addCase(getTodoFieldOption.fulfilled, (state, action) => {
            state.todoFieldOptions = action.payload;
            return;
        });
        builder.addCase(getDealOption.fulfilled, (state, action) => {
            state.dealOptions = action.payload;
            return;
        });
        builder.addCase(getToDosCustomField.fulfilled, (state, action) => {
            state.toDosCustomField = action.payload;
            return;
        });
    }
});

export const { removeSelectedTodo } = toDosSlice.actions;

export default toDosSlice.reducer;
