//** lead-source Api

import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
// Axios Imports
import { toast } from 'react-toastify';
import axios from '@src/service/axios';

export const getUnit = createAsyncThunk('unitSlice/getUnit', async () => {
    try {
        let response = await axios.get('/unit', {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        return Promise.resolve(response?.data?.data);
    } catch (err) {
        console.log('err', err);
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});
export const getUnitOption = createAsyncThunk('unitSlice/getUnitOption', async () => {
    try {
        let response = await axios.get('/unit/options', {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        return Promise.resolve(response?.data?.data);
    } catch (err) {
        console.log('err', err);
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});

export const createUnit = createAsyncThunk('unitSlice/createUnit', async (data, { dispatch }) => {
    try {
        let response = await axios.post('/unit', data, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        toast.success(response?.data?.message || 'Unit added successfully.');
        dispatch(getUnit());
        return Promise.resolve(response);
    } catch (err) {
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        console.log('err', err);
        return Promise.reject(err);
    }
});

export const updateUnit = createAsyncThunk('unitSlice/updateUnit', async (data, { dispatch }) => {
    try {
        let response = await axios.put('/unit/' + data?.id, data?.data, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        toast.success(response?.data?.message || 'Unit  updated successfully.');
        await dispatch(getUnit());
        return Promise.resolve(response);
    } catch (err) {
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        console.log('err', err);
        return Promise.reject(err);
    }
});

export const updateUnitStatus = createAsyncThunk('unitSlice/updateUnitStatus', async (id, { dispatch }) => {
    try {
        let response = await axios.put('/unit/status/' + id, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        toast.success(response?.data?.message || 'Unit Status updated successfully !');
        dispatch(getUnit());
        return Promise.resolve(response);
    } catch (err) {
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        console.log('err', err);
        return Promise.reject(err);
    }
});
export const deleteUnit = createAsyncThunk('unitSlice/deleteUnit', async (deleteId, { dispatch }) => {
    try {
        let response = await axios.delete('/unit/' + deleteId, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        toast.success(response?.data?.message || 'Unit deleted successfully.');
        await dispatch(getUnit());
        return Promise.resolve(response);
    } catch (err) {
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        console.log('err', err);
        return Promise.reject(err);
    }
});

export const unitSlice = createSlice({
    name: 'unitSlice',
    initialState: {
        data: [],
        option: []
    },
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(getUnit.fulfilled, (state, action) => {
            state.data = action.payload;
            return;
        });
        builder.addCase(getUnitOption.fulfilled, (state, action) => {
            state.option = action.payload;
            return;
        });
    }
});

export default unitSlice.reducer;
