//** industry Api

import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
// Axios Imports
import { toast } from 'react-toastify';
import axios from '@src/service/axios';

export const getCustomField = createAsyncThunk('customField/getCustomField', async () => {
    try {
        let response = await axios.get('/custom-field', {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        return Promise.resolve(response?.data?.data);
    } catch (err) {
        console.log('err', err);
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});

export const getCustomFieldOption = createAsyncThunk('customField/getCustomFieldOption', async () => {
    try {
        let response = await axios.get('/custom-field/options', {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        return Promise.resolve(response?.data?.data);
    } catch (err) {
        console.log('err', err);
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});

export const createCustomField = createAsyncThunk('customField/createCustomField', async (data, { dispatch }) => {
    try {
        let response = await axios.post('/custom-field', data, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        toast.success(response?.data?.message || 'Custom Field added successfully.');
        dispatch(getCustomField());
        return Promise.resolve(response);
    } catch (err) {
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        console.log('err', err);
        return Promise.reject(err);
    }
});

export const updateCustomField = createAsyncThunk('customField/updateCustomField', async (data, { dispatch }) => {
    try {
        let response = await axios.put('/custom-field/' + data?.id, data?.data, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        toast.success(response?.data?.message || 'Custom Field updated successfully.');
        await dispatch(getCustomField());
        return Promise.resolve(response);
    } catch (err) {
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        console.log('err', err);
        return Promise.reject(err);
    }
});

export const updateCustomFieldStatus = createAsyncThunk('customField/updateCustomFieldStatus', async (id, { dispatch }) => {
    try {
        let response = await axios.put('/custom-field/status/' + id, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        toast.success(response?.data?.message || 'Custom Field Status updated successfully !');
        dispatch(getCustomField());
        return Promise.resolve(response);
    } catch (err) {
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        console.log('err', err);
        return Promise.reject(err);
    }
});
export const deleteCustomField = createAsyncThunk('customField/deleteCustomField', async (deleteId, { dispatch }) => {
    try {
        let response = await axios.delete('/custom-field/' + deleteId, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        toast.success(response?.data?.message || 'Custom Field deleted successfully.');
        await dispatch(getCustomField());
        return Promise.resolve(response);
    } catch (err) {
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        console.log('err', err);
        return Promise.reject(err);
    }
});

export const customField = createSlice({
    name: 'customField',
    initialState: {
        data: [],
        option: []
    },
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(getCustomField.fulfilled, (state, action) => {
            state.data = action.payload;
            return;
        });
        builder.addCase(getCustomFieldOption.fulfilled, (state, action) => {
            state.option = action.payload;
            return;
        });
    }
});

export default customField.reducer;
