var service = {
    mapLibraries: ['places'],
    API_URL: process.env.REACT_APP_BACKEND_URL
};

// if (process.env.NODE_ENV === 'production') {
//     // service.API_URL = 'http://chpl-dev-api.eduwity.com';
//     service.API_URL = process.env.REACT_APP_BACKEND_URL;
// } else {
//     service.API_URL = 'http://192.168.1.49:5000';
// }

export default service;
