//** Area Api

import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
// Axios Imports
import axios from '@src/service/axios';
import { toast } from 'react-toastify';

export const getCity = createAsyncThunk('citySlice/getCity', async () => {
    try {
        let response = await axios.get('/city', {
            headers: {
                'Content-Type': 'application/json'
            }
        });

        return Promise.resolve(response?.data?.data);
    } catch (err) {
        console.log('err', err);

        toast.error(err?.response?.data?.message || 'Something went wrong!');

        return Promise.reject(err);
    }
});

export const createCity = createAsyncThunk('citySlice/createCity', async (data, { dispatch }) => {
    try {
        let response = await axios.post('/city', data, {
            headers: {
                'Content-Type': 'application/json'
            }
        });

        toast.success(response?.data?.message || 'Country added successfully.');
        await dispatch(getCity());

        return Promise.resolve(response);
    } catch (err) {
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        console.log('err', err);

        return Promise.reject(err);
    }
});

export const updateCity = createAsyncThunk('citySlice/updateCity', async (data, { dispatch }) => {
    try {
        let response = await axios.put('/city/' + data?.id, data?.data, {
            headers: {
                'Content-Type': 'application/json'
            }
        });

        toast.success(response?.data?.message || 'City updated successfully.');
        await dispatch(getCity());

        return Promise.resolve(response);
    } catch (err) {
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        console.log('err', err);

        return Promise.reject(err);
    }
});

export const deleteCity = createAsyncThunk('citySlice/deleteCity', async (deleteId, { dispatch }) => {
    try {
        let response = await axios.delete('/city/' + deleteId, {
            headers: {
                'Content-Type': 'application/json'
            }
        });

        toast.success(response?.data?.message || 'City deleted successfully.');
        await dispatch(getCity());
        return Promise.resolve(response);
    } catch (err) {
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        console.log('err', err);
        return Promise.reject(err);
    }
});

export const updateCityStatus = createAsyncThunk('citySlice/updateCityStatus', async (id, { dispatch }) => {
    try {
        let response = await axios.put('/city/status/' + id, {
            headers: {
                'Content-Type': 'application/json'
            }
        });

        toast.success(response?.data?.message || ' Status updated successfully !');
        dispatch(getCity());

        return Promise.resolve(response);
    } catch (err) {
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        console.log('err', err);

        return Promise.reject(err);
    }
});

export const citySlice = createSlice({
    name: 'citySlice',
    initialState: {
        data: [],
        statesOption: []
    },
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(getCity.fulfilled, (state, action) => {
            state.data = action.payload;

            return;
        });
    }
});

export default citySlice.reducer;
