//** automation Api

import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
// Axios Imports
import { toast } from 'react-toastify';
import axios from '@src/service/axios';
// Loader Components
import showLoader from '@components/loader';

export const getAutomation = createAsyncThunk('automation/getAutomation', async () => {
    try {
        let response = await axios.get('/automation-rule', {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        return Promise.resolve(response?.data?.data);
    } catch (err) {
        console.log('err', err);
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});

export const getAutomationOption = createAsyncThunk('automation/getAutomationOption', async () => {
    try {
        let response = await axios.get('/automation-rule/options', {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        return Promise.resolve(response?.data?.data);
    } catch (err) {
        console.log('err', err);
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});

export const getAutomationById = createAsyncThunk('automation/getAutomationById', async (id) => {
    try {
        showLoader(true);
        let response = await axios.get('/automation-rule/' + id, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        showLoader(false);
        return Promise.resolve(response?.data?.data);
    } catch (err) {
        console.log('err', err);
        showLoader(false);
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});

export const createAutomation = createAsyncThunk('automation/createAutomation', async (data, { dispatch }) => {
    try {
        showLoader(true);
        let response = await axios.post('/automation-rule', data, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        toast.success(response?.data?.message || 'Automation added successfully.');
        showLoader(false);
        dispatch(getAutomation());
        return Promise.resolve(response);
    } catch (err) {
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        showLoader(false);
        console.log('err', err);
        return Promise.reject(err);
    }
});

export const updateAutomation = createAsyncThunk('automation/updateAutomation', async (data, { dispatch }) => {
    try {
        showLoader(true);
        let response = await axios.put('/automation-rule/' + data?.id, data?.data, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        toast.success(response?.data?.message || 'Automation updated successfully.');
        showLoader(false);
        await dispatch(getAutomation());
        return Promise.resolve(response);
    } catch (err) {
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        showLoader(false);
        console.log('err', err);
        return Promise.reject(err);
    }
});

export const updateAutomationStatus = createAsyncThunk('automation/updateAutomationStatus', async (id, { dispatch }) => {
    try {
        let response = await axios.put('/automation-rule/status/' + id, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        toast.success(response?.data?.message || 'Automation Status updated successfully !');
        dispatch(getAutomation());
        return Promise.resolve(response);
    } catch (err) {
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        console.log('err', err);
        return Promise.reject(err);
    }
});
export const deleteAutomation = createAsyncThunk('automation/deleteAutomation', async (deleteId, { dispatch }) => {
    try {
        let response = await axios.delete('/automation-rule/' + deleteId, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        toast.success(response?.data?.message || 'Automation deleted successfully.');
        await dispatch(getAutomation());
        return Promise.resolve(response);
    } catch (err) {
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        console.log('err', err);
        return Promise.reject(err);
    }
});

//account automation field-filter options
export const getAccountAutomationOption = createAsyncThunk('automation/getAccountAutomationOption', async () => {
    try {
        let response = await axios.get('/account-automation/options', {
            headers: {
                'Content-Type': 'application/json'
            }
        });

        return Promise.resolve(response?.data?.data);
    } catch (err) {
        console.log('err', err);
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});

//Deal automation field-filter options
export const getDealAutomationOption = createAsyncThunk('automation/getDealAutomationOption', async () => {
    try {
        let response = await axios.get('/deal-automation/options', {
            headers: {
                'Content-Type': 'application/json'
            }
        });

        return Promise.resolve(response?.data?.data);
    } catch (err) {
        console.log('err', err);
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});

//Lead automation field-filter options
export const getLeadAutomationOption = createAsyncThunk('automation/getLeadAutomationOption', async () => {
    try {
        let response = await axios.get('/lead-automation/options', {
            headers: {
                'Content-Type': 'application/json'
            }
        });

        return Promise.resolve(response?.data?.data);
    } catch (err) {
        console.log('err', err);
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});

//Meetings automation field-filter options
export const getMeetingsAutomationOption = createAsyncThunk('automation/getMeetingsAutomationOption', async () => {
    try {
        let response = await axios.get('/meeting-automation/options', {
            headers: {
                'Content-Type': 'application/json'
            }
        });

        return Promise.resolve(response?.data?.data);
    } catch (err) {
        console.log('err', err);
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});

//Call automation field-filter options
export const getCallAutomationOption = createAsyncThunk('automation/getCallAutomationOption', async () => {
    try {
        let response = await axios.get('/schedule-calls-automation/options', {
            headers: {
                'Content-Type': 'application/json'
            }
        });

        return Promise.resolve(response?.data?.data);
    } catch (err) {
        console.log('err', err);
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});

//Contact automation field-filter options
export const getContactAutomationOption = createAsyncThunk('automation/getContactAutomationOption', async () => {
    try {
        let response = await axios.get('/contact-automation/options', {
            headers: {
                'Content-Type': 'application/json'
            }
        });

        return Promise.resolve(response?.data?.data);
    } catch (err) {
        console.log('err', err);
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});

//to-dos automation field-filter options
export const getTodoAutomationOption = createAsyncThunk('automation/getTodoAutomationOption', async () => {
    try {
        let response = await axios.get('/todo-automation/options', {
            headers: {
                'Content-Type': 'application/json'
            }
        });

        return Promise.resolve(response?.data?.data);
    } catch (err) {
        console.log('err', err);
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});
//sales-team option
export const getSalesTeamOption = createAsyncThunk('automation/getSalesTeamOption', async () => {
    try {
        let response = await axios.get('/sales-team/options', {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        return Promise.resolve(response?.data?.data);
    } catch (err) {
        console.log('err', err);
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});

//new all user
export const getAllNewUserOption = createAsyncThunk('automation/getAllNewUserOption', async () => {
    try {
        let response = await axios.get('/all-user', {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        return Promise.resolve(response?.data?.data);
    } catch (err) {
        console.log('err', err);
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});

//new all user
export const getUserByTeamId = createAsyncThunk('automation/getUserByTeamId', async (id) => {
    try {
        let response = await axios.get('/sales-team-member/' + id, {
            headers: {
                'Content-Type': 'application/json'
            }
        });

        return Promise.resolve(response?.data?.data);
    } catch (err) {
        console.log('err', err);
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});

//reorder automation rules
export const reorderRulesAutomation = createAsyncThunk('automation/reorderRulesAutomation', async (data) => {
    try {
        let response = await axios.put('/automation-rule/update/level', data, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        toast.success(response?.data?.message || 'Automation Rule Level Updated successfully.');
        return Promise.resolve(response?.data?.data);
    } catch (err) {
        console.log('err', err);
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});

export const automation = createSlice({
    name: 'automation',
    initialState: {
        data: [],
        option: [],
        automation: {},
        accountAutomationField: [],
        dealAutomationField: [],
        leadAutomationField: [],
        meetingAutomationField: [],
        callAutomationField: [],
        contactAutomationField: [],
        todoAutomationField: [],
        salesTeamOption: []
    },
    reducers: {
        removeSelectedAutomation: (state, action) => {
            state.automation = {};
        }
    },
    extraReducers: (builder) => {
        builder.addCase(getAutomation.fulfilled, (state, action) => {
            state.data = action.payload;
            return;
        });
        builder.addCase(getAutomationOption.fulfilled, (state, action) => {
            state.option = action.payload;
            return;
        });
        builder.addCase(getAutomationById.fulfilled, (state, action) => {
            state.automation = action.payload;
            return;
        });
        builder.addCase(getAccountAutomationOption.fulfilled, (state, action) => {
            state.accountAutomationField = action.payload;
            return;
        });
        builder.addCase(getDealAutomationOption.fulfilled, (state, action) => {
            state.dealAutomationField = action.payload;
            return;
        });
        builder.addCase(getLeadAutomationOption.fulfilled, (state, action) => {
            state.leadAutomationField = action.payload;
            return;
        });
        builder.addCase(getMeetingsAutomationOption.fulfilled, (state, action) => {
            state.meetingAutomationField = action.payload;
            return;
        });
        builder.addCase(getCallAutomationOption.fulfilled, (state, action) => {
            state.callAutomationField = action.payload;
            return;
        });
        builder.addCase(getContactAutomationOption.fulfilled, (state, action) => {
            state.contactAutomationField = action.payload;
            return;
        });
        builder.addCase(getTodoAutomationOption.fulfilled, (state, action) => {
            state.todoAutomationField = action.payload;
            return;
        });
        builder.addCase(getSalesTeamOption.fulfilled, (state, action) => {
            state.salesTeamOption = action.payload;
            return;
        });
        builder.addCase(getAllNewUserOption.fulfilled, (state, action) => {
            state.newAllUser = action.payload;
            return;
        });
    }
});
export const { removeSelectedAutomation } = automation.actions;

export default automation.reducer;
