// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
// import queryString from 'query-string';

// ** API Imports
import axios from '@src/service/axios';

import { toast } from 'react-toastify';

export const getPipeline = createAsyncThunk('pipelineSliceData/getPipeline', async () => {
    try {
        let response = await axios.get('/pipeline', {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        return Promise.resolve(response?.data?.data);
    } catch (err) {
        console.log('err', err);
        return Promise.reject(err);
    }
});

export const getPipelineOption = createAsyncThunk('pipelineSliceData/getPipelineOption', async () => {
    try {
        let response = await axios.get('/List?option=true', {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        return Promise.resolve(response?.data);
    } catch (err) {
        console.log('err', err);
        return Promise.reject(err);
    }
});

export const deletePipeline = createAsyncThunk('pipelineSliceData/deletePipeline', async (deleteId, { dispatch }) => {
    try {
        let response = await axios.delete('/pipeline/' + deleteId, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        await dispatch(getPipeline());
        return Promise.resolve(response);
    } catch (err) {
        // toast.error(err?.response?.data?.message || 'Something went wrong!');
        console.log('err', err);
        return Promise.reject(err);
    }
});

export const createPipeline = createAsyncThunk('pipelineSliceData/createPipeline', async (data, { dispatch }) => {
    try {
        let response = await axios.post('/pipeline', data, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        await dispatch(getPipeline());
        return Promise.resolve(response);
    } catch (err) {
        // toast.error(err?.response?.data?.message || 'Something went wrong!');
        console.log('err', err);
        return Promise.reject(err);
    }
});

export const updatePipeline = createAsyncThunk('pipelineSliceData/updatePipeline', async (data, { dispatch }) => {
    try {
        let response = await axios.put('/pipeline/' + data?.id, data?.data, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        return Promise.resolve(response);
    } catch (err) {
        // toast.error(err?.response?.data?.message || 'Something went wrong!');
        console.log('err', err);
        return Promise.reject(err);
    }
});

export const pipeline = createSlice({
    name: 'pipelineSliceData',
    initialState: {
        data: []
    },
    extraReducers: (builder) => {
        builder.addCase(getPipeline.fulfilled, (state, action) => {
            state.data = action?.payload;
            return;
        });
    }
});

export default pipeline.reducer;
