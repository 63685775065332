//** lead-source Api

import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
// Axios Imports
import { toast } from 'react-toastify';
import axios from '@src/service/axios';
import showLoader from '@components/loader';

export const getAccount = createAsyncThunk('accountSlice/getAccount', async () => {
    try {
        let response = await axios.get('/account', {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        return Promise.resolve(response?.data?.data);
    } catch (err) {
        console.log('err', err);
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});

export const getAccountPipeline = createAsyncThunk('accountSlice/getAccountPipeline', async () => {
    try {
        let response = await axios.get('/account-pipeline', {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        return Promise.resolve(response?.data?.data);
    } catch (err) {
        console.log('err', err);
        return Promise.reject(err);
    }
});

export const getAccountCallById = createAsyncThunk('contactSlice/getAccountCallById', async (data) => {
    try {
        let response = await axios.get('/account/schedule-calls/' + data?.id + `?status=${data?.status}`, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        return Promise.resolve(response?.data?.data);
    } catch (err) {
        console.log('err', err);
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});

export const getAccountContactsById = createAsyncThunk('contactSlice/getAccountContactsById', async (data) => {
    try {
        let response = await axios.get('/contact/account/' + data, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        return Promise.resolve(response?.data?.data);
    } catch (err) {
        console.log('err', err);
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});

export const getAccountTimeline = createAsyncThunk('accountSlice/getAccountTimeline', async (id, { dispatch }) => {
    try {
        let response = await axios.get('/account-timeline/' + id, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        // toast.success(response?.data?.message);
        return Promise.resolve(response?.data?.data);
    } catch (err) {
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        console.log('err', err);
        return Promise.reject(err);
    }
});

export const updateAccountPipelineTasks = createAsyncThunk('accountSlice/updateAccountPipelineTasks', async (data, { dispatch }) => {
    try {
        let response = await axios.put('/change/account-industry', data, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        // await dispatch(getAccountPipeline());
        return Promise.resolve(response);
    } catch (err) {
        // toast.error(err?.response?.data?.message || 'Something went wrong!');
        console.log('err', err);
        return Promise.reject(err);
    }
});

export const getAccountOption = createAsyncThunk('accountSlice/getAccountOption', async () => {
    try {
        let response = await axios.get('/account/options', {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        return Promise.resolve(response?.data?.data);
    } catch (err) {
        console.log('err', err);
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});

export const getAccountOptionById = createAsyncThunk('accountSlice/getAccountOptionById', async (id) => {
    try {
        let response = await axios.get('/account/cascade/options/' + id, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        return Promise.resolve(response?.data?.data);
    } catch (err) {
        console.log('err', err);
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});

export const getAccountById = createAsyncThunk('accountSlice/getAccountById', async (data) => {
    try {
        let response = await axios.get('/account/' + data, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        return Promise.resolve(response?.data?.data);
    } catch (err) {
        console.log('err', err);
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});

export const getAccountNoteById = createAsyncThunk('accountSlice/getAccountNoteById', async (data) => {
    try {
        let response = await axios.get('/account-note/' + data, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        return Promise.resolve(response?.data?.data);
    } catch (err) {
        console.log('err', err);
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});
export const getAccountAttachmentById = createAsyncThunk('accountSlice/getAccountAttachmentById', async (data) => {
    try {
        let response = await axios.get('/account-attachment/' + data, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        return Promise.resolve(response?.data?.data);
    } catch (err) {
        console.log('err', err);
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});

export const getAccountTodoById = createAsyncThunk('accountSlice/getAccountTodoById', async (data) => {
    try {
        let response = await axios.get('/account/todo/' + data?.id + `?status=${data?.status}`, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        return Promise.resolve(response?.data?.data);
    } catch (err) {
        console.log('err', err);
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});

export const createNote = createAsyncThunk('accountSlice/createNote', async (data) => {
    try {
        let response = await axios.post('/note', data, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        });
        return Promise.resolve(response?.data?.data);
    } catch (err) {
        console.log('err', err);
        // toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});

export const createAttachment = createAsyncThunk('accountSlice/createAttachment', async (data) => {
    try {
        let response = await axios.post('/attachment', data, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        });
        return Promise.resolve(response?.data?.data);
    } catch (err) {
        console.log('err', err);
        // toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});

export const createAccountTodo = createAsyncThunk('accountSlice/createAccountTodo', async (data) => {
    try {
        let response = await axios.post('/todo', data, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        return Promise.resolve(response?.data?.data);
    } catch (err) {
        console.log('err', err);
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});

export const updateNote = createAsyncThunk('accountSlice/updateNote', async (data, { dispatch }) => {
    try {
        let response = await axios.put('/note/' + data?.id, data?.data, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        });
        return Promise.resolve(response?.data?.data);
    } catch (err) {
        console.log('err', err);
        // toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});

export const updateAccountTodo = createAsyncThunk('accountSlice/updateAccountTodo', async (data, { dispatch }) => {
    try {
        let response = await axios.put('/todo/' + data?.id, data?.data, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        return Promise.resolve(response?.data?.data);
    } catch (err) {
        console.log('err', err);
        // toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});

export const updateAttachment = createAsyncThunk('accountSlice/updateAttachment', async (data, { dispatch }) => {
    try {
        let response = await axios.put('/attachment/' + data?.id, data?.data, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        });
        return Promise.resolve(response?.data?.data);
    } catch (err) {
        console.log('err', err);
        // toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});

export const createAccount = createAsyncThunk('accountSlice/createAccount', async (data, { dispatch }) => {
    try {
        showLoader(true);
        let response = await axios.post('/account', data, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        showLoader(false);
        toast.success(response?.data?.message || 'Customer added successfully.');
        dispatch(getAccountByFilter());
        return Promise.resolve(response);
    } catch (err) {
        showLoader(false);
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        console.log('err', err);
        return Promise.reject(err);
    }
});

export const updateAccount = createAsyncThunk('accountSlice/updateAccount', async (data, { dispatch }) => {
    try {
        showLoader(true);
        let response = await axios.put('/account/' + data?.id, data?.data, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        showLoader(false);
        toast.success(response?.data?.message || 'Customer updated successfully.');
        await dispatch(getAccountByFilter());
        return Promise.resolve(response);
    } catch (err) {
        showLoader(false);
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        console.log('err', err);
        return Promise.reject(err);
    }
});

export const updateAccountStatus = createAsyncThunk('accountSlice/updateAccountStatus', async (id, { dispatch }) => {
    try {
        let response = await axios.put('/account/status/' + id, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        toast.success(response?.data?.message || 'Account Status updated successfully !');
        dispatch(getAccountByFilter());
        return Promise.resolve(response);
    } catch (err) {
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        console.log('err', err);
        return Promise.reject(err);
    }
});

export const deleteAccount = createAsyncThunk('accountSlice/deleteAccount', async (deleteId, { dispatch }) => {
    try {
        let response = await axios.delete('/account/' + deleteId, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        toast.success(response?.data?.message || 'Account deleted successfully.');
        await dispatch(getAccountByFilter());
        return Promise.resolve(response);
    } catch (err) {
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        console.log('err', err);
        return Promise.reject(err);
    }
});
export const deleteAccountNote = createAsyncThunk('accountSlice/deleteAccountNote', async (deleteId, { dispatch }) => {
    try {
        let response = await axios.delete('/note/' + deleteId, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        return Promise.resolve(response);
    } catch (err) {
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        console.log('err', err);
        return Promise.reject(err);
    }
});

export const deleteAccountAttachment = createAsyncThunk('accountSlice/deleteAccountAttachment', async (deleteId, { dispatch }) => {
    try {
        let response = await axios.delete('/attachment/' + deleteId, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        return Promise.resolve(response);
    } catch (err) {
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        console.log('err', err);
        return Promise.reject(err);
    }
});

export const deleteAccountTodo = createAsyncThunk('accountSlice/deleteAccountTodo', async (deleteId, { dispatch }) => {
    try {
        let response = await axios.delete('/todo/' + deleteId, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        return Promise.resolve(response);
    } catch (err) {
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        console.log('err', err);
        return Promise.reject(err);
    }
});

//account field-filter options
export const getAccountFilterOption = createAsyncThunk('leadSourceSlice/getAccountFilterOption', async () => {
    try {
        let response = await axios.get('/account-filter/options', {
            headers: {
                'Content-Type': 'application/json'
            }
        });

        return Promise.resolve(response?.data?.data);
    } catch (err) {
        console.log('err', err);
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});

export const getAccountByFilter = createAsyncThunk('leadSlice/getAccountByFilter', async (data, { dispatch }) => {
    try {
        let response = await axios.post('/account-filter', data, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        // toast.success(response?.data?.message || 'Lead added successfully.');
        return Promise.resolve(response?.data?.data);
    } catch (err) {
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        console.log('err', err);
        return Promise.reject(err);
    }
});

export const getAccountMeetingById = createAsyncThunk('accountSlice/getAccountMeetingById', async (data) => {
    try {
        let response = await axios.get('/account/meetings/' + data?.id + `?status=${data?.status}`, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        return Promise.resolve(response?.data?.data);
    } catch (err) {
        console.log('err', err);
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});

//for bulk update customer field
export const getAccountFieldOption = createAsyncThunk('accountSlice/getAccountFieldOption', async () => {
    try {
        let response = await axios.get('/account-field/options', {
            headers: {
                'Content-Type': 'application/json'
            }
        });

        return Promise.resolve(response?.data?.data);
    } catch (err) {
        console.log('err', err);
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});

//Account  bulk update
export const accountBulkUpdate = createAsyncThunk('accountSlice/accountBulkUpdate', async (data, { dispatch }) => {
    try {
        let response = await axios.put('/account-bulk-update', data, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        toast.success(response?.data?.message || 'Bulk updated successfully !');
        dispatch(getAccountByFilter());
        return Promise.resolve(response);
    } catch (err) {
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        console.log('err', err);
        return Promise.reject(err);
    }
});

// bulk delete
export const deleteBulkAccount = createAsyncThunk('accountSlice/deleteBulkAccount', async (deleteIds, { dispatch }) => {
    try {
        let response = await axios.put('/account-bulk-delete', deleteIds, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        toast.success(response?.data?.message || 'Selected Account deleted successfully.');
        dispatch(getAccountByFilter());
        return Promise.resolve(response);
    } catch (err) {
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        console.log('err', err);
        return Promise.reject(err);
    }
});

export const CustomerBulkImport = createAsyncThunk('accountSlice/CustomerBulkImport', async (data, { dispatch }) => {
    try {
        let response = await axios.post('/account-bulk-create', data, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        toast.success(response?.data?.message || 'Bulk Import successfully !');
        dispatch(getAccountByFilter());
        return Promise.resolve(response);
    } catch (err) {
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        console.log('err', err);
        return Promise.reject(err);
    }
});

export const getAccountByPipeline = createAsyncThunk('accountSlice/getAccountByPipeline', async (data, { dispatch }) => {
    try {
        let response = await axios.post('/account-pipeline-filter', data, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        // toast.success(response?.data?.message || 'Lead added successfully.');
        return Promise.resolve(response?.data?.data);
    } catch (err) {
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        console.log('err', err);
        return Promise.reject(err);
    }
});

export const getAccountCustomField = createAsyncThunk('accountSlice/getAccountCustomField', async () => {
    try {
        let response = await axios.get('/account-custom-field', {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        return Promise.resolve(response?.data?.data);
    } catch (err) {
        console.log('err', err);
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});
export const createEmailCustomer = createAsyncThunk('accountSlice/createEmailCustomer', async (data, { dispatch }) => {
    try {
        let response = await axios.post('/emails', data, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        });
        toast.success(response?.data?.message || 'Email Sent successfully');
        return Promise.resolve(response?.data?.data);
    } catch (err) {
        console.log('err', err);
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});
export const getCustomerEmailsSendById = createAsyncThunk('accountSlice/getCustomerEmailsSendById', async (data) => {
    // const query = queryString.stringify(data);
    try {
        let response = await axios.post('/emails/contactIds', data, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        return Promise.resolve(response?.data?.data);
    } catch (err) {
        console.log('err', err);
        // toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});

//Draft
export const getCustomerEmailsDraftById = createAsyncThunk('accountSlice/getCustomerEmailsDraftById', async (data) => {
    // const query = queryString.stringify(data);
    try {
        let response = await axios.post('/emails/contactIds', data, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        return Promise.resolve(response?.data?.data);
    } catch (err) {
        console.log('err', err);
        // toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});

//Schedule
export const getCustomerEmailsScheduleById = createAsyncThunk('accountSlice/getCustomerEmailsScheduleById', async (data) => {
    // const query = queryString.stringify(data);
    try {
        let response = await axios.post('/emails/contactIds', data, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        return Promise.resolve(response?.data?.data);
    } catch (err) {
        console.log('err', err);
        // toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});

export const getCustomerEmailViewById = createAsyncThunk('accountSlice/getCustomerEmailViewById', async (id) => {
    // const query = queryString.stringify(data);
    try {
        let response = await axios.get('/emails/' + id, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        return Promise.resolve(response?.data?.data);
    } catch (err) {
        console.log('err', err);
        // toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});

export const accountSlice = createSlice({
    name: 'accountSlice',
    initialState: {
        data: [],
        options: [],
        accountPipeline: [],
        option: [],
        selectedAccount: [],
        note: [],
        attachment: [],
        todo: [],
        accountFilter: [],
        accountFilterOptions: [],
        call: [],
        meeting: [],
        accountFieldOption: [],
        accountPipelineFilter: [],
        associatedContact: [],
        accountCustomField: [],
        customerEmailsSend: [],
        customerEmailDraft: [],
        customerEmailSchedule: []
    },
    reducers: {
        removeCustomerEmailsSend: (state, action) => {
            state.customerEmailsSend = [];
        },
        removeCustomerEmailsDraft: (state, action) => {
            state.customerEmailDraft = [];
        },
        removeCustomerEmailsSchedule: (state, action) => {
            state.customerEmailSchedule = [];
        },
        removeSelectedCustomer: (state, action) => {
            state.selectedAccount = [];
        }
    },
    extraReducers: (builder) => {
        builder.addCase(getAccount.fulfilled, (state, action) => {
            state.data = action.payload;
            return;
        });
        builder.addCase(getAccountPipeline.fulfilled, (state, action) => {
            state.accountPipeline = action.payload;
            return;
        });
        builder.addCase(getAccountOption.fulfilled, (state, action) => {
            state.options = action.payload;
            return;
        });
        builder.addCase(getAccountById.fulfilled, (state, action) => {
            state.selectedAccount = action.payload;
            return;
        });
        builder.addCase(getAccountNoteById.fulfilled, (state, action) => {
            state.note = action.payload;
            return;
        });
        builder.addCase(getAccountAttachmentById.fulfilled, (state, action) => {
            state.attachment = action.payload;
            return;
        });
        builder.addCase(getAccountTodoById.fulfilled, (state, action) => {
            state.todo = action.payload;
            return;
        });
        builder.addCase(getAccountFilterOption.fulfilled, (state, action) => {
            state.accountFilterOptions = action.payload;
            return;
        });
        builder.addCase(getAccountByFilter.fulfilled, (state, action) => {
            state.accountFilter = action.payload;
            return;
        });
        builder.addCase(getAccountCallById.fulfilled, (state, action) => {
            state.call = action.payload;
            return;
        });
        builder.addCase(getAccountMeetingById.fulfilled, (state, action) => {
            state.meeting = action.payload;
            return;
        });
        builder.addCase(getAccountFieldOption.fulfilled, (state, action) => {
            state.accountFieldOption = action.payload;
            return;
        });
        builder.addCase(getAccountByPipeline.fulfilled, (state, action) => {
            state.accountPipelineFilter = action.payload;
            return;
        });
        builder.addCase(getAccountContactsById.fulfilled, (state, action) => {
            state.associatedContact = action.payload;
            return;
        });
        builder.addCase(getAccountCustomField.fulfilled, (state, action) => {
            state.accountCustomField = action.payload;
            return;
        });
        builder.addCase(getCustomerEmailsSendById.fulfilled, (state, action) => {
            state.customerEmailsSend = action.payload;
            return;
        });
        builder.addCase(getCustomerEmailsDraftById.fulfilled, (state, action) => {
            state.customerEmailDraft = action.payload;
            return;
        });
        builder.addCase(getCustomerEmailsScheduleById.fulfilled, (state, action) => {
            state.customerEmailSchedule = action.payload;
            return;
        });
    }
});

export const { removeCustomerEmailsSend, removeCustomerEmailsSchedule, removeCustomerEmailsDraft, removeSelectedCustomer } =
    accountSlice.actions;
export default accountSlice.reducer;
