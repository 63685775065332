// ** Product Api

import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
// Axios Imports
import { toast } from 'react-toastify';
import axios from '@src/service/axios';

// Lead Report
export const getLeadReport = createAsyncThunk('reportSlice/getLeadReport', async (data) => {
    try {
        let response = await axios.post('/reports/lead', data, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        return Promise.resolve(response?.data?.data);
    } catch (err) {
        console.log('err', err);
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});

// Call Report
export const getCallsReport = createAsyncThunk('reportSlice/getCallsReport', async (data) => {
    try {
        let response = await axios.post('/reports/call', data, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        return Promise.resolve(response?.data?.data);
    } catch (err) {
        console.log('err', err);
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});

// Meetings Report
export const getMeetingsReport = createAsyncThunk('reportSlice/getMeetingsReport', async (data) => {
    try {
        let response = await axios.post('/reports/meeting', data, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        return Promise.resolve(response?.data?.data);
    } catch (err) {
        console.log('err', err);
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});

export const getContactReport = createAsyncThunk('reportSlice/getContactReport', async (data) => {
    try {
        let response = await axios.post('/reports/contact', data, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        return Promise.resolve(response?.data?.data);
    } catch (err) {
        console.log('err', err);
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});

export const getDealReport = createAsyncThunk('reportSlice/getDealReport', async (data) => {
    try {
        let response = await axios.post('/reports/deal', data, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        return Promise.resolve(response?.data?.data);
    } catch (err) {
        console.log('err', err);
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});

export const getCustomerReport = createAsyncThunk('reportSlice/getCustomerReport', async (data) => {
    try {
        let response = await axios.post('/reports/account', data, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        return Promise.resolve(response?.data?.data);
    } catch (err) {
        console.log('err', err);
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});

export const getToDosReport = createAsyncThunk('reportSlice/getToDosReport', async (data) => {
    try {
        let response = await axios.post('/reports/todo', data, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        return Promise.resolve(response?.data?.data);
    } catch (err) {
        console.log('err', err);
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});

export const reportSlice = createSlice({
    name: 'reportSlice',
    initialState: {
        lead: [],
        contact: [],
        calls: [],
        deal: [],
        meetings: [],
        customer: [],
        toDos: []
    },
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(getLeadReport.fulfilled, (state, action) => {
            state.lead = action.payload;
            return;
        });
        builder.addCase(getContactReport.fulfilled, (state, action) => {
            state.contact = action.payload;
            return;
        });
        builder.addCase(getCallsReport.fulfilled, (state, action) => {
            state.calls = action.payload;
            return;
        });
        builder.addCase(getDealReport.fulfilled, (state, action) => {
            state.deal = action.payload;
            return;
        });
        builder.addCase(getMeetingsReport.fulfilled, (state, action) => {
            state.meetings = action.payload;
        });
        builder.addCase(getCustomerReport.fulfilled, (state, action) => {
            state.customer = action.payload;
            return;
        });
        builder.addCase(getToDosReport.fulfilled, (state, action) => {
            state.toDos = action.payload;
            return;
        });
    }
});

export default reportSlice.reducer;
