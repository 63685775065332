//** Lead Api

import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
// Axios Imports
import { toast } from 'react-toastify';
import axios from '@src/service/axios';
// import themeConfig from '../../../configs/themeConfig';

export const get11Za = createAsyncThunk('oneOneZaSlice/get11Za', async () => {
    try {
        let response = await axios.get('/oneoneza-configurations', {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        return Promise.resolve(response?.data?.data);
    } catch (err) {
        console.log('err', err);
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});

export const getMyOperator = createAsyncThunk('oneOneZaSlice/getMyOperator', async () => {
    try {
        let response = await axios.get('/myoperator-configurations', {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        return Promise.resolve(response?.data?.data);
    } catch (err) {
        console.log('err', err);
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});

export const create11za = createAsyncThunk('oneOneZaSlice/create11za', async (data, { dispatch }) => {
    try {
        let response = await axios.post('/oneoneza-configurations', data, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        toast.success(response?.data?.message || 'Lead added successfully.');
        dispatch(get11Za());
        return Promise.resolve(response);
    } catch (err) {
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        console.log('err', err);
        return Promise.reject(err);
    }
});

export const createMyOperator = createAsyncThunk('oneOneZaSliMyOperator', async (data, { dispatch }) => {
    try {
        let response = await axios.post('/myoperator-configurations', data, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        toast.success(response?.data?.message || 'MyOperator  added successfully.');
        dispatch(getMyOperator());
        return Promise.resolve(response);
    } catch (err) {
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        console.log('err', err);
        return Promise.reject(err);
    }
});

export const update11za = createAsyncThunk('oneOneZaSlice/update11za', async (data, { dispatch }) => {
    try {
        let response = await axios.put('/oneoneza-configurations/' + data?.id, data?.data, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        toast.success(response?.data?.message || 'Lead updated successfully.');
        await dispatch(get11Za());
        return Promise.resolve(response);
    } catch (err) {
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        console.log('err', err);
        return Promise.reject(err);
    }
});

export const updateMyOperator = createAsyncThunk('oneOneZaSlice/updateMyOperator', async (data, { dispatch }) => {
    try {
        let response = await axios.post('/myoperator-configurations', data?.data, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        toast.success(response?.data?.message || 'MyOperator updated successfully.');
        await dispatch(getMyOperator());
        return Promise.resolve(response);
    } catch (err) {
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        console.log('err', err);
        return Promise.reject(err);
    }
});

export const delete11za = createAsyncThunk('oneOneZaSlice/delete11za', async (deleteId, { dispatch }) => {
    try {
        let response = await axios.delete('/oneoneza-configurations/' + deleteId, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        toast.success(response?.data?.message || 'Lead deleted successfully.');
        await dispatch(get11Za());
        return Promise.resolve(response);
    } catch (err) {
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        console.log('err', err);
        return Promise.reject(err);
    }
});

export const deleteMyOperator = createAsyncThunk('oneOneZaSlice/deleteMyOperator', async (deleteId, { dispatch }) => {
    try {
        let response = await axios.delete('/myoperator-configurations', {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        toast.success(response?.data?.message || 'MyOperator deleted successfully.');
        await dispatch(getMyOperator());
        return Promise.resolve(response);
    } catch (err) {
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        console.log('err', err);
        return Promise.reject(err);
    }
});

export const oneOneZaSlice = createSlice({
    name: 'oneOneZaSlice',
    initialState: {
        data: [],
        myOperator: []
    },
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(get11Za.fulfilled, (state, action) => {
            state.data = action.payload;
            return;
        });
        builder.addCase(getMyOperator.fulfilled, (state, action) => {
            state.myOperator = action.payload;
            return;
        });
    }
});

export default oneOneZaSlice.reducer;
